<template>
  <div v-if="dialog" class="popup_overlay">
  <div class="custom_dialog bg-white" style="width:890px;max-height:750px;">
    <div class="cust_card">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">Add User</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="addemployeeType()">Save</button>
        </div>  
      </div>
      <div>
        <div class="p-2 grid grid-cols-12">
          <div class="box col-span-6 p-2">
            <p class="label_css">First Name</p>
            <input
            :class="employeeTypeObjErr.firstNameErr ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
            class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
            placeholder="First Name"
            v-model="employeeTypeObj.firstName"
            >
            <p class="text-error" v-if="employeeTypeObjErr.firstNameErr">First Name is Required</p>
          </div>
          <div class="box col-span-6 p-2">
            <p class="label_css">Last Name</p>
            <input
            :class="employeeTypeObjErr.lastNameErr ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
            class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
            placeholder="Last Name"
            v-model="employeeTypeObj.lastName"
            >
            <p class="text-error" v-if="employeeTypeObjErr.lastNameErr">Last Name is Required</p>
          </div>
          <div class="box col-span-6 p-2">
            <p class="label_css">Login Email</p>
            <input
            :class="employeeTypeObjErr.emailErrMsg !== '' ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
            class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
            @blur="checkForEmail('basicEmail')"
            @keydown="checkForEmail('basicEmail')"
            @input="CheckEmail('basicEmail')"
            placeholder="Login Email"
            v-model="employeeTypeObj.emailAddress"
            >
            <p class="mb-0 text-error cursor-pointer" v-if="emailOverlap !== null && employeeTypeObjErr.emailErrMsg !== ''">
              <a>({{ emailOverlap[0].firstName }} {{ emailOverlap[0].lastName }})
              </a>
            </p>
            <p class="text-error" v-if="employeeTypeObjErr.emailErrMsg !== ''">{{employeeTypeObjErr.emailErrMsg}}</p>
          </div>
          <div class="box col-span-6 p-2">
            <p class="label_css">Password</p>
            <div :class="employeeTypeObjErr.passwordErr ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"  class="flex bg-white  items-center outline-none text-gray-900 text-sm rounded  pl-2 h-12 heading-4">
                <input id="useridPassword" class="w-96 py-2 cust_text_field"
                  v-model="passwordVal"  
                  placeholder="Password"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                >
                <span v-if="!showPassword" @click="showPassword = !showPassword" class="cursor-pointer"><img height="30px" width="30px" src="@/assets/images/svg/hide-pass.svg" alt="clearInput"></span>
                <span v-else @click="showPassword = !showPassword" class="cursor-pointer"><img height="20px" width="30px" src="@/assets/images/svg/show-pass.svg" alt="clearInput"></span>
            </div>
            <p class="text-error" v-if="employeeTypeObjErr.passwordErr">Password is Required</p>
          </div>
          <!-- <p class="text-error" v-if="employeeTypeObjErr.employeeTypeNameErr">Employee Type Is Required</p> -->
        </div>
        <div class="p-4 py-2 flex items-center">
          <input type="checkbox" class="h-5 w-5 cursor-pointer" @click="showAdminPopup(employeeTypeObj.isSuperAdmin)" v-model="employeeTypeObj.isSuperAdmin">
          <p class=" text-text1 pl-2 heading-5">Is Super Admin</p>
        </div>
        <div class="p-4 py-2 flex items-center">
          <input type="checkbox" class="h-5 w-5 cursor-pointer"  v-model="employeeTypeObj.isActive">
          <p class=" text-text1 pl-2 heading-5">Is Active</p>
        </div>
        <div class="p-4 py-2 flex items-center" v-if="showDeleteBtn">
          <span class="flex items-center cursor-pointer" @click="removeUser()">
            <i class="fas fa-trash-alt text-error h-5 w-5"></i>
            <p class=" text-text1 pl-2 heading-5">Delete User</p>
          </span>
        </div>
      </div>
    </div>
    <div v-if="showAdminDialog" class="popup_overlay">
          <div style="width: 500px;" class="custom_dialog">
            <div class="text-text1 p-3">
              <span class="heading-3">Are You Sure ?</span>
              <p class="text-gray3 heading-4 pt-3">Do you want to make <span class="font-bold text-primary">{{employeeTypeObj.firstName }} {{employeeTypeObj.lastName}}</span> super Admin?</p>
            </div>
            <div class="flex justify-end pr-4 bg-blue-400 p-2">
              <button class="bg-gray-100 text-black shadow mr-2 rounded-full px-5 py-1.5" @click="showAdminDialog = false;employeeTypeObj.isSuperAdmin = false">No</button>
              <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="showAdminDialog = false;employeeTypeObj.isSuperAdmin = true">Yes</button>
            </div>
          </div>
        </div>
  </div>
</div>
</template>
<script>
import {getEncryptedPassword} from '@/utils/encrytPassword'
import HRM from '@/View/CMS/api/HRM.js'
import {validateEmail} from '@/utils/validation.js'
export default {
  props: ['userId'],
  data () {
    return {
      emailOverlap: null,
      showAdminDialog: false,
      showDeleteBtn: false,
      showPassword: false,
      passwordVal: '',
      userDetailValue: [],
      employeeTypeObj: {
        userDetailId: 0,
        password: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        isSuperAdmin: false,
        isActive: true
      },
      employeeTypeObjErr: {
        firstNameErr: false,
        lastNameErr: false,
        emailErrMsg: '',
        passwordErr: false
      },
      submitted: false,
      shiftlist: [],
      selectedShiftId: '',
      Obj2: {},
      Obj: {},
      cardView: false,
      dialog: true,
      employeeTypeList: []
    }
  },
  validations: {
  },
  computed: {
  },
  mounted () {
    console.log('userId', this.userId)
    if (this.userId > 0) {
      this.showDeleteBtn = true
      this.employeeTypeObj.userDetailId = this.userId
      this.userDetail()
    }
  },
  watch: {
    employeeTypeObj: {
      handler () {
        console.log("employeeTypeObj", this.employeeTypeObj)
        if (this.employeeTypeObj.firstName !== '') {
        this.employeeTypeObjErr.firstNameErr = false
        }
        if (this.employeeTypeObj.lastName !== '') {
          this.employeeTypeObjErr.lastNameErr = false
        }
        if (this.employeeTypeObj.emailAddress !== '') {
          this.emailValidation(this.employeeTypeObj.emailAddress)
        } else if (this.employeeTypeObj.emailAddress === '') {
          this.employeeTypeObjErr.emailErrMsg = ''
        }
        if (this.employeeTypeObj.password !== '') {
          this.employeeTypeObjErr.passwordErr = false
        }
      },
      deep: true
    },
    passwordVal: {
      handler () {
        this.employeeTypeObj.password = getEncryptedPassword(this.passwordVal)
      },
      deep: true
    }
  },
  methods: {
    showAdminPopup (val) {
      if (val === false) {
        this.showAdminDialog = true
      }
      console.log('val', val)
    },
    CheckEmail () {
      if (this.employeeTypeObjErr.emailErrMsg !== '') {
        this.checkForEmail()
      }
    },
    checkForEmail () {
      if (this.employeeTypeObj.emailAddress !== '' && validateEmail(this.employeeTypeObj.emailAddress) !== false) {
        HRM.checkEmailVal(
          this.employeeTypeObj.emailAddress,
          response => {
            if (response.message === 'email exited' && response.Data !== null) {
              this.employeeTypeObjErr.emailErrMsg = 'This email already taken'
              this.emailOverlap = response.Data
            } else if (response.message === 'email exited' && response.Data === null) {
              this.employeeTypeObjErr.emailErrMsg = ''
              this.emailOverlap = null
            }
            console.log('emailOverlap', this.emailOverlap)
          },
          error => {
            if (error.message === 'No record found') {
              this.employeeTypeObjErr.emailErrMsg = ''
              this.emailOverlap = null
            }
          }
        )
      }
    },
    removeUser () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
      HRM.deleteUser(
        this.employeeTypeObj.userDetailId,
        response => {
          this.$root.$emit('closeDialog', false)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {  
          // console.log(error)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    userDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
      HRM.getUserDetail(
        this.employeeTypeObj.userDetailId,
        response => {
          this.userDetailValue = response.Data
          this.employeeTypeObj.userDetailId = this.userDetailValue.userDetailId
          this.employeeTypeObj.password = ''
          this.employeeTypeObj.firstName = this.userDetailValue.firstName
          this.employeeTypeObj.lastName = this.userDetailValue.lastName
          this.employeeTypeObj.emailAddress = this.userDetailValue.emailAddress
          this.employeeTypeObj.isSuperAdmin = this.userDetailValue.isSuperAdmin
          this.employeeTypeObj.isActive = this.userDetailValue.isActive
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
        },
        error => {
          // console.log(error)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    resizeGrid () {
      if (window.innerWidth < 960) {
        this.cardView = true
      } else {
        this.cardView = false
      }
    },
    emailValidation (emailId) {
      if (emailId === '') {
        this.employeeTypeObjErr.emailErrMsg = 'Email is Required'
        this.emailOverlap = null
      } else if (validateEmail(emailId) === false) {
        this.employeeTypeObjErr.emailErrMsg = 'Email is Invalid'
        this.emailOverlap = null
      } else {
        this.employeeTypeObjErr.emailErrMsg = ''
        this.emailOverlap = null
      }
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    addemployeeType () {
      if (this.employeeTypeObj.firstName === '') {
        this.employeeTypeObjErr.firstNameErr = true
      }
      if (this.employeeTypeObj.lastName === '') {
        this.employeeTypeObjErr.lastNameErr = true
      }
      if (this.employeeTypeObj.emailAddress === '') {
        this.employeeTypeObjErr.emailErrMsg = 'Email is Required'
      }
      if (this.employeeTypeObj.password === '') {
        this.employeeTypeObjErr.passwordErr = true
      }
      if (!this.employeeTypeObjErr.firstNameErr && !this.employeeTypeObjErr.lastNameErr && this.employeeTypeObjErr.emailErrMsg === '' && !this.employeeTypeObjErr.passwordErr) {
        // console.log(this.employeeTypeObj)
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        HRM.addNewUser(
          this.employeeTypeObj,
          response => {
            // console.log(response)
            this.$root.$emit('closeDialog', false)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            // console.log(error)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    closeDetails () {
      this.$root.$emit('closeDialog', false)
    }
  }
}
</script>
<style scoped>
.profiles {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-item-group,
.v-expansion-panels {
  z-index: 0 !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 0 20px 0 !important;
}
.content {
  padding: 5px 5px;
}
.checkbox {
  color: grey;
  font-size: 14px;
}
.checkbox1 {
  color: grey;
  font-size: 11px;
}
.checkbox2 {
  color: grey;
  font-size: 11px;
}
.buttons .button {
  margin-bottom: 0rem;
}
.input:focus {
  outline: none;
  border-color: 0 0 0 0.125em rgba(31, 82, 190, 0.25);
  box-shadow: none;
}
a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: white;
  color: black;
}
.datetimepicker .datetimepicker-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  position: relative;
  padding: 0.3rem 1rem;
}
.box:not(:last-child),
.content:not(:last-child),
.notification:not(:last-child),
.progress:not(:last-child),
.table:not(:last-child),
.table-container:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.block:not(:last-child),
.highlight:not(:last-child),
.breadcrumb:not(:last-child),
.level:not(:last-child),
.list:not(:last-child),
.message:not(:last-child),
.tabs:not(:last-child) {
  margin-bottom: 0rem;
}
.autocomplete .dropdown-content {
  overflow: auto;
  max-height: 200px;
}
.buttons:last-child {
  margin-bottom: 0rem;
}
</style>
